import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Button, MyTooltip } from "@library";
import axios from "@helpers/axios/private.axios";

import "./index.sass";

const Balance = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataBalance = async () => {
      try {
        setLoading(true);
        const resp = await axios.get("/users/balance");

        setData(resp.data.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    getDataBalance();
  }, []);

  return (
    <div className="balance">
      {!loading && (
        <>
          <header className="balance__available">
            <p className="balance__available-text">
              Available balance: <span>{data.balance}</span>
            </p>
          </header>

          <Grid container rowSpacing="24px" columnSpacing="15px">
            <Grid md={4} xs={6} item>
              <article className="balance-card">
                <div className="balance-card__top">
                  <p className="balance-card__text">Visitors</p>
                  <MyTooltip
                    title="The total number of new visitors to the platform."
                    placement="right-start"
                  />
                </div>
                <span className="balance-card__val">{data.visitors}</span>
              </article>
            </Grid>
            <Grid md={4} xs={6} item>
              <article className="balance-card">
                <div className="balance-card__top">
                  <p className="balance-card__text">Registrations</p>
                  <MyTooltip
                    title="The total number of new user registrations."
                    placement="right-start"
                  />
                </div>
                <span className="balance-card__val">{data.registrations}</span>
              </article>
            </Grid>
            <Grid md={4} xs={6} item>
              <article className="balance-card">
                <div className="balance-card__top">
                  <p className="balance-card__text">FTD</p>
                  <MyTooltip
                    title="The number of users who made their first deposit."
                    placement="right-start"
                  />
                </div>
                <span className="balance-card__val">{data.ftd}</span>
              </article>
            </Grid>
            <Grid md={4} xs={6} item>
              <article className="balance-card">
                <div className="balance-card__top">
                  <p className="balance-card__text">Deposits</p>
                  <MyTooltip
                    title="The total amount of money deposited on the platform."
                    placement="right-start"
                  />
                </div>
                <span className="balance-card__val">{data.deposits}</span>
              </article>
            </Grid>
            <Grid md={4} xs={6} item>
              <article className="balance-card">
                <div className="balance-card__top">
                  <p className="balance-card__text">Commission</p>
                  <MyTooltip
                    title="The total commission earned from user activity."
                    placement="right-start"
                  />
                </div>
                <span className="balance-card__val">{data.commission}</span>
              </article>
            </Grid>
            <Grid md={4} xs={6} item>
              <article className="balance-card">
                <div className="balance-card__top">
                  <p className="balance-card__text">Hold</p>
                  <MyTooltip
                    title="The amount of commission currently on hold."
                    placement="right-start"
                  />
                </div>
                <span className="balance-card__val">{data.hold}</span>
              </article>
            </Grid>
          </Grid>

          <Button
            type="link"
            className="balance-card__btn"
            to="/wallet/withdrawal"
            color="orange"
            size="middle"
          >
            Withdrawal
          </Button>
        </>
      )}
    </div>
  );
};

export default Balance;
