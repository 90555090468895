const config = {
  // Configuration file
  api_url: process.env.REACT_APP_API_URL,
  app_url: process.env.REACT_APP_URL,

  supported_locales: ["en", "ru"],
  infoEmail: "info@reviarte.com",
  supportEmail: "support@reviarte.com",
};

export default config;
