import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AffiliateLinks,
  Auth,
  CreatePassword,
  Dashboard,
  Login,
  Main,
  NotFound,
  Offers,
  Profile,
  RecoverPassword,
  Settings,
  SignUpHash,
  SingUp,
  UpdatedPassword,
  Wallet,
  Withdrawal,
} from "@components/pages";

import ScrollToTop from "@components/common/ScrollToTop";
import MyAlert from "../library/UI/Alert";
import LayoutWithAside from "@components/layout/Layout/LayoutWithAside";
import { Analytics } from "../pages";

export const AuthWrapper = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      <ScrollToTop />
      <MyAlert />

      <Routes>
        {isAuth ? (
          <>
            <Route path="/" element={<LayoutWithAside />}>
              <Route index element={<Dashboard />} />
              <Route path="/auth/*" element={<Navigate to="/" />} />
              <Route path="/wallet">
                <Route index element={<Wallet />} />
                <Route path="withdrawal" element={<Withdrawal />} />
              </Route>
              <Route path="affiliate-links" element={<AffiliateLinks />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/offers" element={<Offers />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </>
        ) : (
          <>
            <Route index element={<Main />} />

            <Route path="/auth" element={<Auth />}>
              <Route index element={<Login />} />
              <Route path="signup" element={<SingUp />} />
              <Route path="recover-password" element={<RecoverPassword />} />
              <Route
                path="create-password/:email/:code"
                element={<CreatePassword />}
              />
              <Route path="updated-password" element={<UpdatedPassword />} />

              <Route path="*" element={<Login />} />
            </Route>
          </>
        )}

        <Route path="auth/verify/:hash" element={<SignUpHash />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
