import Logo from "@assets/images/Logo-grey.svg";
import { Link } from "react-router-dom";

import "./footer.sass";
import config from "../../../config";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <Link to={"/"} className="footer__logo">
          <img src={Logo} alt="logo" />
        </Link>

        <div className="footer__text-wrap">
          <p className="footer__text">
            Reviarte © {new Date().getFullYear()}. All Rights Reserved.
          </p>
          <p className="footer__text">
            General information:{" "}
            <a href={`mailto:${config.infoEmail}`}>{config.infoEmail}</a>
          </p>
          <p className="footer__text">
            Technical support:{" "}
            <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
          </p>
        </div>
      </div>
      <div className="footer__bottom">
        <nav className="footer__nav">
          <ul className="footer__list">
            <li className="footer__item">
              <Link
                to={`${config.app_url}/page/privacy-policy`}
                className="footer__link"
                target={"_blank"}
              >
                Privacy Policy
              </Link>
            </li>
            <li className="footer__item">
              <Link
                to={`${config.app_url}/page/terms-and-conditions`}
                className="footer__link"
                target={"_blank"}
              >
                Terms of Use
              </Link>
            </li>
            <li className="footer__item">
              <Link
                to={`${config.app_url}/page/aml-policy`}
                className="footer__link"
                target={"_blank"}
              >
                KYC/AML Policy
              </Link>
            </li>
            <li className="footer__item">
              <Link
                to={`${config.app_url}/page/cookie-policy`}
                className="footer__link"
                target={"_blank"}
              >
                Cookie Policy
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};
