import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";
import Popup from "@components/library/UI/Popup";
import Check from "@components/pages/Auth/components/CheckInbox";
import Input from "@components/library/UI/Input";
import { Button } from "@components/library";
import PasswordRequirements from "@components/pages/Auth/components/PasswordRequirements";
import { registration } from "@actions/auth";
import { useDispatch } from "react-redux";
import config from "../../../config";

export const SingUp = () => {
  const [isTypePassword, setIsTypePassword] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");

  const onSubmit = (data) => {
    console.log("RESULT", data, isValid);
    setLoading(true);
    const { email, password } = data;
    dispatch(registration({ email, password }))
      .then(() => {
        setModalActive(email);
        reset();
      })
      .catch((error) => {
        console.log(error.response.data);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form
        onChange={() => setError(false)}
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form"
      >
        <h2 className="auth-form__title">Create account</h2>

        <p className="auth-form__text">
          Already have an account?&nbsp;<Link to="/auth/login">Sign in</Link>
        </p>

        <Input
          className={"auth-form__inp-wrap"}
          data={{
            label: "Email:",
            type: "email",
            placeholder: "Enter email",
            errors,
            settings: {
              ...register("email", {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              }),
            },
            message: "* Invalid email format",
            name: "email",
          }}
        />

        <Input
          className={"auth-form__inp-wrap"}
          data={{
            label: "Password:",
            type: isTypePassword ? "password" : "text",
            placeholder: "Enter password",
            errors,
            settings: {
              ...register("password", {
                required: true,
                minLength: 8,
                pattern:
                  /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
              }),
            },
            name: "password",
          }}
        >
          <button
            type="button"
            className={`bo-input__icon bo-input__icon--eye ${
              !isTypePassword ? "open" : ""
            }`}
            onClick={() => setIsTypePassword(!isTypePassword)}
          ></button>
        </Input>

        <PasswordRequirements password={password} />

        <ul className="auth-form__list-agree list-agree">
          <li className="list-agree__item">
            <input
              className="bo-checkbox"
              id="pp"
              type="checkbox"
              {...register("privacy", {
                required: true,
              })}
            />
            <label htmlFor="pp" className="bo-checkbox-label">
              <p>
                I agree to the&nbsp;
                <Link
                  target={"_blank"}
                  to={`${config.app_url}/page/terms-and-conditions`}
                >
                  Terms of Use
                </Link>
                &nbsp; and &nbsp;
                <Link
                  target={"_blank"}
                  to={`${config.app_url}/page/privacy-policy`}
                >
                  &nbsp;Privacy Policy
                </Link>
              </p>
            </label>
          </li>
        </ul>

        {error && (
          <p className="auth-form__error">The email has already been taken.</p>
        )}

        <Button
          className="auth-form__btn"
          disabled={!isValid}
          size="middle"
          type="submit"
          color="orange"
          isLoading={loading}
        >
          Sign up
        </Button>
      </form>
      <Popup active={modalActive} setActive={setModalActive}>
        <Check email={modalActive} />
      </Popup>
    </>
  );
};

export default SingUp;
